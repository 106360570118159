/* You can add global styles to this file, and also import other style files */
@import "./themes/quidel.theme.scss";

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                    supported by Chrome, Opera and Firefox */
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

mat-dialog-content {
  padding: 0 !important;
}

.mdc-dialog__title {
  padding: 0 !important;
}

.mdc-dialog .mdc-dialog__content {
  padding: 0 !important;
}

.mdc-dialog__actions {
  padding: 0 !important;
}

.mat-mdc-list-base {
  --mdc-list-list-item-label-text-size: 14px;
}

.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line,
.mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line,
.mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
  height: 48px !important;
}

.mdc-list-item--with-leading-checkbox .mdc-list-item__start {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.mat-mdc-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.status {
  width: 16px;
  height: 16px;
  border-radius: 50%;
}

.status-healthy {
  background-color: #9af453;
}

.status-unknown {
  background-color: #ffde59;
}

.status-unhealthy {
  background-color: #ed1c24;
}

.status-unregistered {
  background-color: #999;
}

input[type=checkbox] {
  cursor: pointer;
}

.leftside-slidetoggle-label {
  @extend .mat-mdc-slide-toggle;
  padding-right: 4px;
  font-family: var(--mat-slide-toggle-label-text-font);
  font-size: var(--mat-slide-toggle-label-text-size);
  letter-spacing: var(--mat-slide-toggle-label-text-tracking);
  line-height: var(--mat-slide-toggle-label-text-line-height);
  font-weight: var(--mat-slide-toggle-label-text-weight);
}

.dialog-root {
  padding: 24px;
  letter-spacing: normal;
}

.ngx-toastr {
  width: fit-content !important;
  min-width: 300px;
  max-width: 600px;
}
