@use '@angular/material' as mat;
// https://material.angular.io/guide/theming
// https://material.angular.io/guide/theming-your-components

// https://github.com/Teradata/covalent/blob/develop/src/theme.scss

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!

// https://material.angular.io/guide/typography

$siteFontFamily: 'Open Sans, sans-serif';

$custom-typography: mat.define-typography-config(
  $font-family: $siteFontFamily,
  $button: mat.define-typography-level(14px, $letter-spacing: normal),
);
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
@include mat.all-component-typographies($custom-typography);
@include mat.core();

// https://material.io/design/color/the-color-system.html
// https://github.com/angular/material2/blob/master/src/lib/core/theming/_palette.scss

@import "./mat-variables";
@import "./variables";
@import "./palette";

$primary: mat.define-palette($mat-black, 900);
$accent:  mat.define-palette(mat.$deep-purple-palette, 500, A100, A400);
$warn:    mat.define-palette(mat.$red-palette, 600);

$theme: mat.define-light-theme((
  color: (
    primary: $primary,
    accent: $accent,
    warn: $warn,
  ),
  density: -1 // https://stackoverflow.com/a/75194777/10752002
));

@include mat.all-component-themes($theme);

// https://material.angular.io/guide/theming-your-components

html, body {
  height: 100%;
  overflow: hidden;

  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.18);
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.18);
  --mat-menu-item-label-text-size: 14px;
}
body {
  margin: 0;
  font-family: $siteFontFamily;
}
